@import "../../style/variables";

.Header {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 1rem $border-padding;
  padding-bottom: .5rem;
  border-bottom: .8rem solid rgb(0, 44, 73);
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1000;

  .logo-img {
    height: 4rem;
    object-fit: contain;
  }

  .globe, .menu {
    visibility: hidden;
  }
}

@media screen and (min-width: 768px) {

}
