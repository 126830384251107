@import  './style/variables';

// Base stylesheet
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  font-size: 1.4rem;
  font-weight: normal;
}

header, footer {
  flex: 0;
}

main {
  flex: 1;
  height: 100%;
  width: 100%;
}

a {
  text-decoration: none;

  &:visited {
    color: $text-color;
  }
}