@import './style/variables';

body {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 62.5%;
}

h1 {
  font-size: 2.6rem;
}

h2 {
  font-size: 2.4rem;
}

h3 {
  font-size: 2.2rem;
}

h4 {
  font-size: 2rem;
}

h5 {
  font-size: 1.8rem;
}

h6 {
  font-size: 1.6rem;
}

p {
  font-size: 1.4rem;
  margin: 0;
}

main {
  background-image: linear-gradient(rgb(234,234,234), rgb(255, 255, 255));
}

#root {
  height: 100%;
}

.state-link {
  color: $teal;
  text-decoration: none;
  font-weight: 600;

  &:visited, &:active {
    color: $teal;
  }
}