@import "../../style/variables";

.Text {
  color: $text-color;
  line-height: $line-height;

  &.bold {
    font-weight: 700;
  }

  &.center {
    text-align: center;
  }
}