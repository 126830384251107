$border-padding: 2.2rem;
$orange: rgb(232, 123, 15);
$black: rgb(234, 234, 234);

$teal: rgb(42, 139, 167);

$text-color: rgb(71, 71, 71);
$red: rgb(210, 34, 71);

$line-height: 1.5;

$bp-desktop: 768px;

$container-width: 1260px;
