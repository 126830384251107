@import "../../style/variables";

.Footer {
  border-top: .5rem solid $red;
}

.footer-ballot-drop {
  font-size: 1rem;
  padding: 1.6rem 0;
}

.social-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1.6rem;
}

.social-icons-item {
  margin: 0 1rem;
}

.svg path {
  fill: #929292;
}

.footer-links-list, .voter-info-project {
  font-size: .8rem;
}

.footer-links-list {
  display: flex;
  justify-content: space-around;
  text-align: center;
  .divider {
    display: none;
  }
  color: black;
  a {
    color: black;
    margin: 0 .3rem;
  }
  padding-bottom: 1rem;
}

@media screen and (min-width: 350px) {
  .footer-links-list {
    display: flex;
    justify-content: space-around;
    .divider {
      display: inline;
      margin: 0;
    }
    color: black;
    a {
      color: black;
    }
    padding-bottom: .5rem;
  }

  .footer-links-list, .voter-info-project {
    font-size: 1rem;
  }
}